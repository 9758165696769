/* src/BachelorsPage.css */
.bachelors-page {
    /* font-family: Arial, sans-serif; */
    max-width: 1000px;
    margin: 0 auto;
    padding-top: 150px;
}

.bachelors-header {
    background-color: #f5f5f5;
    padding: 20px;
    text-align: center;
    border-radius: 8px;
}